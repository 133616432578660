export default {
  init() {
    // JavaScript to be fired on all pages
    // Open Menu --------------------------
    var $body = $('body');
    var $openMenu = $('.open_menu');
    var $navItem = $('.nav-item');

    $openMenu.on('click touch', function() {
      console.log('click open');
      $body.toggleClass('show-menu');
    });

    $navItem.on('click touch', function() {
      $body.removeClass('show-menu');
    });

    $('#flags').appendTo('.navbar-nav');

    // document.getElementsByClassName('navbar-nav').appendChild(
    //   document.getElementById('flags')
    // );

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
